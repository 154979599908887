<template>
  <gl-modal
    v-bind="$attrs"
    class="pp-modal"
    full-buttons
    submit-title="Apply"
    title="Rename Case"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="pp-modal__rename">
      <div class="pp-modal__rename-block">
        <div class="pp-modal__rename-block-header">
          Current title
        </div>
        <div class="pp-modal__rename-block-current">
          <div class="pp-modal__rename-block-current-el">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="pp-modal__rename-block">
        <div class="pp-modal__rename-block-header">
          New title
        </div>
        <div class="pp-modal__rename-block-input">
          <gl-input
            v-model="title"
            class="gl-modal__row"
            :height="32"
            name="value"
            :rules="rules"
          />
        </div>
      </div>
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'

export default {
  components: {
    GlModal,
    GlInput,
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    rules() {
      return `required`
    },
  },
  methods: {
    onSubmit() {
      this.$emit('update', this.title)
    },
  },
}
</script>
